import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService{

    constructor(){}

    public encrypt(strToEncrypt: string): string | null{
        let res: string | null = null;
        
        try{
            let key = CryptoJS.enc.Utf8.parse(environment.aesEncryptionKey)
            let iv = CryptoJS.enc.Utf8.parse(environment.aesIv)

            res = CryptoJS.AES.encrypt(strToEncrypt, key, {
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
        }catch(e){
            console.log("Ocurrio un error al encriptar el texto")
        }

        return res;
    }
}